import { FuryPage } from '@netfleets/fms-types';
import { Badge, Color, Tooltip, Typography, Variant, Weight } from '@netfleets/frontend-components';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FURY_ROUTE_PREFIX } from '../../../shared/config';
import { PRIVACY_POLICY_LINK } from '../../../shared/languageConfig';
import { useFuryLocation } from '../../hooks/useFuryLocation';
import styles from './NavigationMenu.css';
import NavigationMenuLink from './NavigationMenuLink';

interface NavigationMenuProps {
  pages: FuryPage[];
  onClose: () => void;
}

interface ModuleTag {
  showUntil: Date;
  labelTranslationKey: string;
  toolTipTranslationKey: string;
}

interface Module {
  translationKey: string;
  hideWhenNotAvailable?: boolean;
  tag?: ModuleTag;
}

interface ModuleCategory {
  title?: string;
  modules: Module[];
}

const menu: ModuleCategory[] = [
  {
    modules: [
      { translationKey: 'DASHBOARD' },
      { translationKey: 'USERS', hideWhenNotAvailable: true },
      { translationKey: 'API_KEYS', hideWhenNotAvailable: true },
      { translationKey: 'LOCATION_MANAGEMENT', hideWhenNotAvailable: true },
    ],
  },
  {
    title: 'BASICS',
    modules: [{ translationKey: 'INVENTORIES' }, { translationKey: 'LOCATION_LIST' }],
  },
  {
    title: 'ANALYSIS',
    modules: [
      { translationKey: 'FLEET_INSIGHTS' },
      { translationKey: 'OPERATIONAL_COSTS' },
      { translationKey: 'PRODUCTIVITY' },
      {
        translationKey: 'STATE_OF_CHARGE',
        tag: {
          showUntil: new Date('2024-12-31'),
          labelTranslationKey: 'Menu.STATE_OF_CHARGE.tag.LABEL_TEXT',
          toolTipTranslationKey: 'Menu.STATE_OF_CHARGE.tag.TOOLTIP_TEXT',
        },
      },
      { translationKey: 'SERVICE_REPORTS' },
      { translationKey: 'MAINTENANCE' },
      {
        translationKey: 'DATA_INSIGHTS',
        tag: {
          showUntil: new Date('2024-12-31'),
          labelTranslationKey: 'Menu.DATA_INSIGHTS.tag.LABEL_TEXT',
          toolTipTranslationKey: 'Menu.DATA_INSIGHTS.tag.TOOLTIP_TEXT',
        },
      },
    ],
  },
  {
    title: 'SAFETY',
    modules: [
      { translationKey: 'ACCESS_SHOCK_MANAGEMENT' },
      { translationKey: 'OPERATIONS' },
      { translationKey: 'PREOPCHECK' },
    ],
  },
];

const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {
  const userPages: FuryPage[] = [
    {
      route: '/',
      elementName: '',
      translationKey: 'DASHBOARD',
      menuArea: 'top',
    },
    ...props.pages,
  ];

  const miscPages = props.pages.filter((app) => app.menuArea === 'misc');
  const { t } = useTranslation();
  const location = useFuryLocation();

  const navigationMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let targetNode = e.target as Element;
      while (targetNode.parentElement !== null) {
        if (
          (navigationMenuRef.current && navigationMenuRef.current.contains(targetNode)) ||
          targetNode.id === 'navigation-menu-button'
        ) {
          return;
        }

        targetNode = targetNode.parentElement;
      }

      props.onClose();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = props.onClose;

  return (
    <>
      <div className={styles.menuOverlay} onClick={props.onClose} />
      <nav className={styles.wrapper} ref={navigationMenuRef}>
        {menu.map((moduleCategory) => (
          <ul className={styles.mainColumn} key={moduleCategory.title || 'overview'}>
            {moduleCategory.title ? (
              <div className={styles.headline}>
                <Typography variant={Variant.CAPTION} color={Color.BG06}>
                  {t(`Header.${moduleCategory.title}`)}
                </Typography>
              </div>
            ) : (
              <div className={styles.overviewColumn} />
            )}
            {moduleCategory.modules.map((module) => {
              const foundPage: FuryPage | undefined = userPages.find(
                (app) => app.translationKey === module.translationKey
              );

              if (!foundPage && module.hideWhenNotAvailable) {
                return null;
              }

              return (
                <li key={module.translationKey}>
                  {foundPage ? (
                    <NavigationMenuLink
                      badge={
                        module.tag &&
                        module.tag.showUntil > new Date() && (
                          <Badge content={t(module.tag.labelTranslationKey)} color="success" variant="square" />
                        )
                      }
                      text={t(`Apps.${module.translationKey}`)}
                      href={foundPage.route}
                      isActive={
                        location.baseRoute === foundPage.route ||
                        (location.pathname === '/' && module.translationKey === 'DASHBOARD')
                      }
                      onClick={handleMenuClick}
                    />
                  ) : (
                    <Tooltip
                      content={
                        module.tag ? t(module.tag.toolTipTranslationKey) : t('Menu.HOVER_STATED_UNBOOKED_MODULE')
                      }
                      size="large"
                      placement="bottom"
                      disableInteractive
                    >
                      <>
                        <NavigationMenuLink
                          badge={
                            module.tag &&
                            module.tag.showUntil > new Date() && (
                              <Badge content={t(module.tag.labelTranslationKey)} color="success" variant="square" />
                            )
                          }
                          text={t(`Apps.${module.translationKey}`)}
                          href="#"
                          isDisabled
                        />
                      </>
                    </Tooltip>
                  )}
                </li>
              );
            })}
          </ul>
        ))}
        <ul className={styles.miscLinksColumn}>
          {miscPages.map((page) => (
            <li key={`Apps.${page.translationKey}`}>
              <NavigationMenuLink
                isSmall
                text={t(`Apps.${page.translationKey}`)}
                href={page.route}
                onClick={handleMenuClick}
              />
            </li>
          ))}
          <li>
            <NavigationMenuLink
              isSmall
              isExtern
              text={t('Apps.API_DOCUMENTATION')}
              href={window.fury.config.BACKEND_API_DOCUMENTATION}
              onClick={handleMenuClick}
            />
          </li>
          <li>
            <NavigationMenuLink
              isSmall
              text={t('Apps.IMPRINT')}
              href={`${FURY_ROUTE_PREFIX}/imprint`}
              onClick={handleMenuClick}
            />
          </li>
          <li>
            <NavigationMenuLink
              isSmall
              isExtern
              text={t('Apps.PRIVACY')}
              href={PRIVACY_POLICY_LINK}
              onClick={handleMenuClick}
            />
          </li>
          <li>
            <NavigationMenuLink
              isSmall
              text={t('Apps.PRIVACY_PREFERENCES')}
              href={`${FURY_ROUTE_PREFIX}/privacy`}
              onClick={handleMenuClick}
            />
          </li>
        </ul>
        <div className={styles.copyright}>
          <Typography variant={Variant.CAPTION} color={Color.BG06} weight={Weight.NORMAL}>
            © {new Date().getFullYear()} Jungheinrich AG
          </Typography>
        </div>
      </nav>
    </>
  );
};

export default NavigationMenu;
